import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import Toast from 'components/common/toast';
import Map, { Marker, Polyline } from 'components/common/map';
import history from 'helpers/history';
import api from 'helpers/api';

import TripTabs from '../trip-tabs/';

const baseUrl = 'https://storage.googleapis.com/flamingo-static/images/admin/';
const icons = {
  start: { url: baseUrl + 'trip-start.png', size: new window.google.maps.Size(25, 25), scaledSize: new window.google.maps.Size(25, 25), anchor: new window.google.maps.Point(12, 12) },
  end: { url: baseUrl + 'trip-end.png', size: new window.google.maps.Size(25, 25), scaledSize: new window.google.maps.Size(25, 25), anchor: new window.google.maps.Point(12, 12) },
  active: { url: baseUrl + 'vehicle-inuse.png', size: new window.google.maps.Size(29, 29), scaledSize: new window.google.maps.Size(29, 29), anchor: new window.google.maps.Point(14, 14) },
};

const paymentStatusColors = {
  'successful': '#20BF6B',
  'pending': '#F39C12',
  'failed': '#EB4D4B',
};

const paymentStatuses = [
  { value: 'successful', label: 'Successful' },
  { value: 'pending', label: 'Pending' },
  { value: 'failed', label: 'Failed' },
];

class Trip extends Component {
  constructor(props) {
    super(props);
    const preloadedTrip = props.location.state ? props.location.state.trip : false;
    this.state = { trip: preloadedTrip || {}, popCostVisible: false, tripPatch: {}, loading: true };

    this.loadTrip = this.loadTrip.bind(this);
    this.updateTrip = this.updateTrip.bind(this);
    this.handleError = this.handleError.bind(this);

    this.viewStripe = this.viewStripe.bind(this);
    this.handleAdjustCost = this.handleAdjustCost.bind(this);
    this.adjustCost = this.adjustCost.bind(this);
    this.onChange = this.onChange.bind(this);
    this.retryPayment = this.retryPayment.bind(this);
    this.handleTicket = this.handleTicket.bind(this);
    this.handleReceipt = this.handleReceipt.bind(this);

    this.hidePop = this.hidePop.bind(this);
    this.popBackgroundClick = this.popBackgroundClick.bind(this);
    this.popBackground = React.createRef();
  }

  componentDidMount() {
    document.title = `Trip ${this.props.match.params.tripId} | Flamingo Admin`;
    this.loadTrip();
  }

  /* NETWORKING */

  loadTrip() {
    this.setState({ loading: true });
    return api.get(`/trip/${this.props.match.params.tripId}`)
      .then((res) => this.setState({ trip: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  updateTrip(newTrip) {
    const trip = this.state.trip;
    const keys = Object.keys(newTrip);
    for (var i = 0; i < keys.length; i++) {
      const key = keys[i];
      trip[key] = newTrip[key];
    }
    this.setState({ trip, loading: false });
  }

  /* ACTIONS */

  viewStripe() {
    const { stripeChargeId } = this.state.trip;
    if (stripeChargeId) {
      window.open(`https://dashboard.stripe.com/payments/${stripeChargeId}`);
    }
  }

  retryPayment() {
    this.setState({ loading: true });
    return api.post(`/trip/${this.props.match.params.tripId}/retry-payment`)
      .then((res) => this.updateTrip(res.data.data))
      .catch(this.handleError);
  }

  handleTicket() {
    const subject = prompt('Please enter a subject line. This will be visible to the rider. Eg "Parking Review".');
    if (subject !== null) {
      const note = prompt('Please enter information for the support team to:');
      if (note !== null) {
        this.setState({ loading: true });
        return api.post('/support/ticket', { tripId: this.props.match.params.tripId, subject, note })
          .then((res) => history.push(`/support/ticket/${res.data.data.id}`))
          .catch(this.handleError);
      }
    }
  }

  handleReceipt() {
    const email = prompt('Please enter an email to send the receipt to:', this.state.trip.user.email);
    if (email !== null) {
      this.setState({ loading: true });
      return api.post(`/trip/${this.props.match.params.tripId}/receipt`, { email })
        .then((res) => this.setState({ loading: false }, () => alert('Receipt sent!')))
        .catch(this.handleError);
    }
  }

  /* Cost Editor */

  handleAdjustCost() {
    const { trip } = this.state;
    const tripPatch = {
      paymentStatus: trip.paymentStatus,
      actualCost: (trip.actualCost / 100).toFixed(2),
    };
    this.setState({ popCostVisible: true, tripPatch });
  }

  onChange(e) {
    const { tripPatch, trip } = this.state;
    const key = e.target.getAttribute('field');
    tripPatch[key] = e.target.value;
    if (trip.actualCost === 0 && key === 'actualCost' && parseFloat(e.target.value) > 0) {
      tripPatch.paymentStatus = 'pending';
    } else if (key === 'actualCost' && parseFloat(e.target.value) === 0) {
      tripPatch.paymentStatus = 'successful';
    }
    this.setState({ tripPatch, error: false });
  }

  adjustCost() {
    const { tripPatch } = this.state;
    this.setState({ loading: true, popCostVisible: false });
    const data = {
      actualCost: Math.round(tripPatch.actualCost * 100),
      paymentStatus: tripPatch.paymentStatus,
    };
    return api.post(`/trip/${this.props.match.params.tripId}/adjust-cost`, data)
      .then((res) => this.updateTrip(res.data.data))
      .catch(this.handleError);
  }

  hidePop() {
    this.setState({ popCostVisible: false });
  }

  popBackgroundClick(e) {
    if (e.target === this.popBackground.current) {
      this.hidePop();
    }
  }

  /* RENDERS */

  render() {
    const { tripId } = this.props.match.params;
    const { trip, popCostVisible, tripPatch, loading, error } = this.state;

    // Navigation
    const title = `Trip ${this.props.match.params.tripId}`;
    const actions = [];
    if (trip.stripeChargeId) {
      actions.push({ onClick: this.viewStripe, icon: 'cc-stripe' });
    }
    if (window.innerWidth > 450) {
      if (trip.vehicle && trip.user){
        actions.push({ onClick: this.handleReceipt, icon: 'receipt' });
      }
      actions.push({ onClick: this.handleTicket, icon: 'life-ring' });
    }

    actions.push({ to: { pathname: `/report/trip/${tripId}`, state: { trip } }, icon: 'exclamation-triangle' });
    actions.push({ onClick: this.loadTrip, icon: 'sync-alt' });

    // Map
    let mapOptions;
    if (trip.encodedPolyline) {
      mapOptions = { center: { lat: trip.startLatitude, lng: trip.startLongitude }, zoom: 14 };
    } else if (trip.vehicle) {
      mapOptions = { center: { lat: trip.vehicle.latitude, lng: trip.vehicle.longitude }, zoom: 16 };
    }

    // Subscription
    const hasSubscription = trip.user ? trip.user.hasSubscription : false;

    // Render
    return (
      <div className="fm-trip">
        <NavigationBar title={title} copyUrl={true} loading={loading} showBack={true} rightActions={actions} />
        <div className="fm-trip-content">
          <div className="fm-trip-map">
            {
              mapOptions ? (
                <Map options={mapOptions} google={window.google}>
                  { (trip.vehicle && trip.status === 'ACTIVE') && <Marker position={{ lat: trip.vehicle.latitude, lng: trip.vehicle.longitude }} title={trip.vehicle.registration} icon={icons.active} /> }
                  { (trip.startLatitude && trip.startLongitude) && <Marker position={{ lat: trip.startLatitude, lng: trip.startLongitude }} title="Start" icon={icons.start} onClick={() => window.open(`https://www.google.co.nz/maps?q=${trip.startLatitude},${trip.startLongitude}`)} /> }
                  { (trip.endLatitude && trip.endLongitude) && <Marker position={{ lat: trip.endLatitude, lng: trip.endLongitude }} title="End" icon={icons.end} onClick={() => window.open(`https://www.google.co.nz/maps?q=${trip.endLatitude},${trip.endLongitude}`)} /> }
                  { trip.encodedPolyline && <Polyline encodedPath={trip.encodedPolyline} strokeColor={'#ff206e'} strokeWeight={'4'} /> }
                </Map>
              ) : null
            }
          </div>
          <div className="fm-trip-section-header">
            <p className="fm-trip-section-title">Details</p>
          </div>
          <div className="fm-trip-status">
            <div className="fm-trip-status-item">
              <div className="fm-trip-status-item-icon">
                <Icon icon="wave-square" />
              </div>
              <p className="fm-trip-status-item-text">{ trip.status || '---' }</p>
            </div>
            <Link className="fm-trip-status-item" to={{ pathname: trip.user ? `/user/${trip.user.id}` : '#', state: { user: trip.user } }}>
              <div className={`fm-trip-status-item-icon${ hasSubscription ? ' fm-trip-status-item-icon-pink' : '' }`}>
                <Icon icon="user" />
              </div>
              <p className="fm-trip-status-item-text">{ trip.user ? `${trip.user.firstName} ${trip.user.lastName}` : '---' }</p>
            </Link>
            <Link className="fm-trip-status-item" to={{ pathname: trip.vehicle ? `/vehicle/${trip.vehicle.id}` : '#', state: { vehicle: trip.vehicle } }}>
              <div className="fm-trip-status-item-icon">
                <Icon icon="dot-circle" />
              </div>
              <p className="fm-trip-status-item-text">{ trip.vehicle ? trip.vehicle.registration : '---' }</p>
            </Link>
          </div>
          <div className="fm-trip-duration">
            <div className="fm-trip-duration-inner">
              {
                trip.startTime ? (
                  <>
                    <div className="fm-trip-duration-line"></div>
                    <p className="fm-trip-duration-time">{ moment(trip.startTime).format('h:mma D/MM/YY') }</p>
                    <p className="fm-trip-duration-value">{ moment(trip.endTime || undefined).diff(trip.startTime, 'minutes') } mins</p>
                    <p className="fm-trip-duration-time">{ trip.endTime ? moment(trip.endTime).format('h:mma D/MM/YY') : 'Now' }</p>
                  </>
                ) : null
              }
            </div>
          </div>
          <div className="fm-trip-section-header">
            <p className="fm-trip-section-title">Payment</p>
            <div className="fm-trip-section-button" onClick={this.handleAdjustCost}>
              <Icon icon="pen" />
            </div>
          </div>
          <div className="fm-trip-payment">
            <div className="fm-trip-payment-row">
              <p className="fm-trip-payment-label">Standard Cost</p>
              {
                trip.standardCost ? (
                  <p className="fm-trip-payment-value">${ (trip.standardCost / 100).toFixed(2) } { trip.currency }</p>
                ) : (
                  <p className="fm-trip-payment-value">N/A</p>
                )
              }
            </div>
            {
              (trip.standardCost && trip.standardCost > 0 && trip.standardCost !== trip.actualCost && (trip.standardCost - trip.actualCost) > 0) ? (
                <div className="fm-trip-payment-row">
                  <p className="fm-trip-payment-label">Discount</p>
                  <p className="fm-trip-payment-value">${ ((trip.standardCost - trip.actualCost) / 100).toFixed(2) } { trip.currency }</p>
                </div>
              ) : null
            }
            <div className="fm-trip-payment-row">
              <p className="fm-trip-payment-label">Actual Cost</p>
              {
                trip.actualCost ? (
                  <p className="fm-trip-payment-value">${ (trip.actualCost / 100).toFixed(2) } { trip.currency }</p>
                ) : (
                  <p className="fm-trip-payment-value">{ trip.status === 'ACTIVE' ? 'N/A' : '$0' }</p>
                )
              }
            </div>
            <div className="fm-trip-payment-row">
              <p className="fm-trip-payment-label">Payment Status</p>
              {
                trip.paymentStatus &&
                <div className="fm-trip-payment-status">
                  <p className="fm-trip-payment-status-value" style={{ backgroundColor: paymentStatusColors[trip.paymentStatus] }}>{ trip.paymentStatus.toUpperCase() }</p>
                  {
                    (trip.paymentStatus === 'successful' && trip.stripeChargeId) ? (
                      <p className="fm-trip-payment-status-button" onClick={this.viewStripe}>Stripe<Icon icon="chevron-right" /></p>
                    ) : (
                      (trip.status === 'FINISHED' && trip.paymentStatus !== 'successful' && !loading) ? (
                        <p className="fm-trip-payment-status-button" onClick={this.retryPayment}>Retry<Icon icon="redo-alt" /></p>
                      ) : null
                    )
                  }
                </div>
              }
            </div>
          </div>
          <div className="fm-trip-section-header">
            <p className="fm-trip-section-title">Review</p>
          </div>
          <div className="fm-trip-review">
            <p className="fm-trip-review-text">{ trip.review ? `${trip.review.rating} stars - ${trip.review.message || 'No message'}` : 'The user did not leave a rating or review for this trip.' }</p>
          </div>
          {
            trip.parkingReview &&
            <>
              <div className="fm-trip-section-header">
                <p className="fm-trip-section-title">Parking Review</p>
              </div>
              <div className="fm-trip-review">
                <p className="fm-trip-review-text">{ trip.parkingReview }</p>
              </div>
            </>
          }
          <TripTabs trip={trip} />
        </div>

        {
          popCostVisible &&
          <div className="fm-support-ticket-pop" ref={this.popBackground} onClick={this.popBackgroundClick}>
            <div className="fm-support-ticket-pop-box">
              <div className="fm-support-ticket-pop-header">
                <p className="fm-support-ticket-pop-title">Update Actual Cost</p>
                <p className="fm-support-ticket-pop-description">Changes in cost must be manually updated on Stripe.</p>
              </div>
              <div className="fm-support-ticket-pop-options">
                <div className="fm-support-ticket-pop-input">
                  <p className="fm-input-label">Amount</p>
                  <input value={tripPatch.actualCost} className="fm-input" field="actualCost" onChange={this.onChange} type="number" step="0.01" min="0" inputMode="decimal" placeholder="0.00" />
                  <p className="fm-input-hint">Enter the new actual cost of the trip in decimal format. For example $3 as "3.00".</p>
                </div>
                <div className="fm-support-ticket-pop-input">
                  <p className="fm-input-label">Status</p>
                  <select value={tripPatch.paymentStatus} className="fm-input fm-input-select" field="paymentStatus" onChange={this.onChange}>
                    { paymentStatuses.map((type, i) => <option key={i} value={type.value}>{type.label}</option>) }
                  </select>
                  <p className="fm-input-hint">Mark Successful if the amount has been paid, or Pending if payment is required.</p>
                </div>
              </div>
              <div className="fm-support-ticket-pop-footer">
                <button className="fm-support-ticket-pop-destructive" onClick={this.adjustCost}>Save</button>
                <button className="fm-support-ticket-pop-cancel" onClick={this.hidePop}>Cancel</button>
              </div>
            </div>
          </div>
        }
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default Trip;
